<template>
    <div id="step-one">
        <div class="slider-day">
            <p>{{ $t('sessions') }}</p>
            <div class="current-day-selected">
                <span class="arrow left-arrow" @click="dayBeforeHeader()"> <img src="./../../assets/icons/arrow.png" alt=""> </span>
                <p>{{ transformDate }}</p>
                <span class="arrow right-arrow" @click="dayAfterHeader()"> <img src="./../../assets/icons/arrow.png" alt=""> </span>
            </div>
        </div>
        <div v-if="experiencies.length > 0" id="step-one-box-inner" class="step-one-content">
            <minimal-player-selector v-if="getExperiences().length && isMultiPrice()" @change-field="onSelectPlayers"/>
            <experience v-for="(experience,key) in experiencies"
                        v-bind:key="key"
                        @experience-selected="onExperienceSelected"
                        :show-experience-name="showExperienceName"
                        :call-sunday="callSunday"
                        :call="call"
                        :show-room-name="showRoomName"
                        :experience-content=experience
                        :more-info="moreInfo"
                        :multi-booking="multiBooking"
                        :url-experience="urlExperience"
            > </experience>
        </div>
        <div v-else class="step-one-content no-experiencie">
            <p v-if="!noExperience">{{ $t('loading') }}</p>
            <p v-else>{{ $t('noExperiences') }}</p>
        </div>
    </div>
</template>

<script>
    import experiences from './experiences.js';
    import Experience from '../experience/experience.vue';
    import MinimalPlayerSelector from '../checkout/form-experience/input-components/minimal-player-selector.vue'

    export default {
        extends: experiences,
        name: 'experiences',
        components: {
            Experience,MinimalPlayerSelector
        },
    };
</script>

<style lang="scss" scoped>
    @import "experiences";
</style>