<template>
    <div id="calendar" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white', 'minimal': getDisplayMode() === 'minimal'}" v-if="days.length">
        <div class="line-month">
            <span v-if="showArrowLeft" @click="previusMonth()" class="arrow left-arrow"> <img src="./../../assets/icons/arrow.png" alt=""> </span>
            <span class="month-name">{{ monthName }} {{ currentYear }}</span>
            <span @click="nextMonth()" class="arrow right-arrow"> <img src="./../../assets/icons/arrow.png" alt=""> </span>
        </div>
        <div class="line-header">
            <div class="week">
                <span>{{ $t('monday') }}</span>
            </div>
            <div class="week">
                <span>{{ $t('tuesday') }}</span>
            </div>
            <div class="week">
                <span>{{ $t('wednesday') }}</span>
            </div>
            <div class="week">
                <span>{{ $t('thursday') }}</span>
            </div>
            <div class="week">
                <span>{{ $t('friday') }}</span>
            </div>
            <div class="week">
                <span>{{ $t('saturday') }}</span>
            </div>
            <div class="week">
                <span>{{ $t('sunday') }}</span>
            </div>
        </div>
        <div class="line-days">
            <div class="day" v-for="(gap, index) in gaps" v-bind:key="index"></div>
            <div v-for="(day,index) in lastDay" :key="`${index}-${lastDay}`" :class="dayObject(day, isEnabled(day))" @click="selectDay(day)" class="day">
                <span>{{ day }}</span>
            </div>
        </div>
        <div class="info-calendar">
            <div class="info-calendar-box">
                <span id="free"></span> {{ $t('freeSession') }}
            </div>
            <div class="info-calendar-box">
                <span id="low"></span> {{ $t('lastSession') }}
            </div>
            <div class="info-calendar-box">
                <span id="close"></span> {{ $t('sessionComplete') }}
            </div>
        </div>
        <div class="powered-by">powered by <a href="https://proescape.es/servicios/sistema-gestor-de-reservas/">AION</a></div>
    </div>
</template>

<script>
    import calendar from './calendar';
    export default {
        extends: calendar,
        name: 'calendar',
        props: []
    };
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";
    @import "calendar.scss";
</style>
