export default {
    props: ["paymentMethod"],
    data() {
      return {
          showStripe: true
      }
    },
    mounted() {
        this.loadStripe();
    },
    methods: {
        loadStripe: function () {
            let stripe = Stripe(this.paymentMethod.form.key);
            const elements = stripe.elements();
            const style = {
                base: {
                    fontSize: '16px',
                    color: '#32325d',paymentMethod
                },
            };
            const card = elements.create('card', {style});
            card.mount('#card-element');

            const form = document.getElementById('payment-form-stripe');
            let isSubmitting = false;
            form.addEventListener('submit', async (event) => {
                event.preventDefault();
                if(isSubmitting){
                    return;
                }
                isSubmitting = true;
                const {token, error} = await stripe.createToken(card);
                if (error) {
                    isSubmitting = false;
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = error.message;
                } else {
                    this.stripeTokenHandler(token);
                }
            });
        },
        stripeTokenHandler: function (token) {

            let form = document.getElementById('final-form');
            form.action = this.paymentMethod.form.action;

            let hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', token.id);

            form.appendChild(hiddenInput);
            form.submit();
        }

    }
}