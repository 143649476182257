<template>
    <div id="experience" v-bind:class="{'dark': getDesign() == 'black', 'white' : getDesign() == 'white', 'minimal-experience': getDisplayMode() === 'minimal', 'external-experience': getDisplayMode() !== 'minimal'}">
        <div class="background-box" v-bind:style="{ background: 'url(' + experienceContent.image + ') no-repeat top center' }">
            <a target="_blank" :href="experienceUrl" class="more-info-button" v-if="moreInfo">{{ $t('moreInfo') }}</a>
            <p v-if="showExperienceName" class="experience-name">{{ experienceContent.name }}</p>
            <div class="background-box-icons">
                <div class="icon">
                    <img src="./assets/people.png" alt="">
                    {{ experienceContent.minPlayers }} - {{ getMaxPlayers }} {{ $t('people') }}
                </div>
                <div class="icon">
                    <img src="./assets/time_info.png" alt="">
                    {{ experienceContent.duration }} {{ $t('minutes') }}
                </div>
                <div class="icon" v-bind:class="{'no-visible': !experienceContent.ageinfo}">
                    <img :src="require('./assets/older.png')" alt="">
                    {{ experienceContent.ageinfo }}
                </div>
                <div class="icon" v-bind:class="{'no-visible': !experienceContent.location}">
                    <img :src="require('./assets/location.png')" alt="">
                    {{ experienceContent.location }}
                </div>
            </div>
        </div>
        <div class="content-box" v-if="getDisplayMode() !== 'minimal'">
            <div v-if="!isMultiPrice() && multiBooking" class="multi-calendar-selector">
                {{ $t('wantReserve') }}
                <input type="checkbox" v-model="multiCalendarCheck" @click="setMultiCalendar">
            </div>
            <div class="content-box-calendar" v-for="(calendar, index) in getCalendars" v-bind:key="index">
                <div v-if="calendar.schedules.length > 0">
                    <div v-if="showRoomName == 'calendario'" class="name-box-content">
                        {{ calendar.name }}
                    </div>
                    <div v-else-if="showRoomName == 'icono'" class="name-box-content">
                        <img :src="calendar.icon" alt="">
                    </div>
                </div>
                <div class="content-box-hours-list" v-if="getDisplayMode() == 'lista'">
                    <schedule-list
                        v-for="(schedule,key) in calendar.schedules"
                        :key="key + '' + keyList"
                        :schedule="schedule"
                        :calendar="calendar.id"
                        :experience-content="experienceContent"
                        :currentDay="currentDay"
                        :call-sunday="callSunday"
                        :call="call"
                        :currentDayPlus="currentDayPlus"
                        :dateSelected="getDateSelected()"
                        @schedule-selected="selectSchedule"
                    />
                </div>

                <div v-else class="content-box-hours-compact">
                    <schedule-compact
                        v-for="(schedule,key) in calendar.schedules"
                        :key="key + '' + keyList"
                        :schedule="schedule"
                        :calendar="calendar.id"
                        :experience-content="experienceContent"
                        :currentDay="currentDay"
                        :call-sunday="callSunday"
                        :call="call"
                        :currentDayPlus="currentDayPlus"
                        :dateSelected="getDateSelected()"
                        @schedule-selected="selectSchedule"
                    />
                </div>
            </div>
            <div v-if="multiCalendar" v-bind:class="{'button-content' : true, 'enabled' : buttonEnabled}" class="button-content">
                <div class="button button-flat" @click="experienceSelectedButton()">{{ $t('confirmMultipleReserve') }}</div>
            </div>
        </div>
    </div>
</template>

<script>

    import ScheduleList from './schedule/schedule-list/schedule-list.vue';
    import ScheduleCompact from './schedule/schedule-compact/schedule-compact.vue';

    import experience from './experience';
    export default {
        extends: experience,
        name: 'experience',
        components: {
            ScheduleList,
            ScheduleCompact
        },
    };
</script>

<style lang="scss" scoped>
    @import "experience";
</style>