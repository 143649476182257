<template>
    <div v-if="experiencies.length > 0" id="step-one-box-inner">
        <minimal-experience v-for="(experience,key) in experiencies"
            v-bind:key="key"
            @experience-selected="onExperienceSelected"
            :show-experience-name="showExperienceName"
            :call-sunday="callSunday"
            :call="call"
            :show-room-name="showRoomName" 
            :experience-content=experience
            :more-info="moreInfo"
            :multi-booking="multiBooking"
            :url-experience="urlExperience"/>
    </div>
</template>

<script>
    import MinimalExperience from '../experience/minimal-experience.vue'
    import experiences from './experiences.js';

    export default {
        extends: experiences,
        name: "minimal-experiences",
        components: {
            MinimalExperience
        },
        methods: {
            onExperienceSelected() {
                this.$emit('next-step');
            }
        }
    }
</script>