class ItemCheckout {

    constructor(id_experience, id_calendario, start, startHour, end, endHour, people_number, price_original, price_fractioned, total_players, language) {
        this.id_experience = id_experience,
        this.id_calendario = id_calendario,
        this.start = start,
        this.startHour = startHour,
        this.end = end,
        this.endHour = endHour,
        this.people_number = people_number,
        this.price_original = price_original,
        this.price_fractioned = price_fractioned,
        this.fields = [],
        this.total_players = total_players,
        this.language = language || "Spanish"
    }
}

export default ItemCheckout;