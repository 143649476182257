<template>
  <div class="general" :class="{ 'multi-price': isMultiple }">
    <div class="left-box-zone" v-show="canShow">
      <calendar
        v-if="showCalendar"
        @date-selected="onDateSelected"
        @date-selected-click="onDateSelected"
        @cancel-purchase="confirmCancelPurchase"
      />
      <more-info
        :title="$t('generalInformation')"
        v-if="showMoreInfoGeneral && moreInfoText.length > 0"
        :description="moreInfoText"
      />
    </div>
    <div
      class="right-box-zone"
      v-bind:style="{ minHeight: minHeight + 'px' }"
      v-show="canShow"
    >
      <div v-if="!isAvailableDayShow">
        <experiences
          v-if="canShowStepOne()"
          :key="stepOneKey"
          :more-info="showMoreInfoExperience"
          :show-experience-name="showExperienceName"
          :show-room-name="showRoomName"
          :multi-booking="multiBooking"
          :call-sunday="callSunday"
          :call="call"
          :url-experience="urlInfoExperience"
          @load-experience="getCheckoutUrl"
          @next-step="onNextStep"
        />
        <checkout
          v-if="canShowStepTwo()"
          @cancel-purchase="confirmCancelPurchase"
          :terms="terms"
          :call-sunday="callSunday"
        />
      </div>
      <div class="no-available-days" v-else>
        <p>{{ $t("noExperiencesMonth") }}</p>
      </div>
    </div>
    <loading v-if="!canShow" />
  </div>
</template>

<script>
import Calendar from "../components/calendar/calendar.vue";
import Experiences from "./experiences/experiences.vue";
import MoreInfo from "./mini-components/more-info.vue";
import Checkout from "./checkout/checkout.vue";
import Loading from "./loading/loading.vue";
import { mapMutations, mapGetters } from "vuex";
import api from "@/api";

export default {
  name: "external-calendar",
  extends: api,
  components: {
    Calendar,
    MoreInfo,
    Experiences,
    Checkout,
    Loading,
  },
  props: ["showCalendar", "integration"],
  data() {
    return {
      call: this.integration.call,
      callSunday: this.integration.sundayCall,
      isAvailableDayShow: false,
      moreInfoText: this.integration.text ?? "",
      minHeight: 0,
      returnFromCheckout: false,
      showMoreInfoExperience: this.integration.moreinfo,
      multiBooking: this.integration.multibooking,
      showExperienceName: this.integration.calname,
      showMoreInfoGeneral: this.integration.info,
      showRoomName: this.integration.line,
      stepOneKey: 0,
      terms: this.integration.terms ?? "",
      urlInfoExperience: JSON.parse(this.integration.urls),
      isMultiple: false,
      canShow: false,
    };
  },
  methods: {
    ...mapMutations(['refreshState', 'updateDateSelected','updateCheckout', 'updateShowStepOne', 'updateShowStepTwo']),
    onDateSelected: function (dateSelected) {
      if (this.canShowStepTwo) this.cancelPurchase();

      this.updateShowStepOne(true);
      this.updateShowStepTwo(false);

      this.updateDateSelected({ dateSelected: dateSelected });
      this.refreshState();

      this.stepOneKey++;

      if (this.stepOneKey > 1) {
        setTimeout(() => {
          if (document.getElementById("step-one")) {
            this.minHeight = document.getElementById("step-one").offsetHeight;
          }

          document.getElementById("step-one").scrollIntoView();
        }, 1000);
      }
    },
    onNextStep: function () {
      this.updateShowStepOne(false);
      this.updateShowStepTwo(true);
    },
    getCheckoutUrl: function () {
      let checkout = new URLSearchParams(window.location.search).get(
        "checkout"
      );
      if (checkout && !this.returnFromCheckout) {
        let idPurchase = checkout;
        let getPurchasePromise = new Promise((resolve) => {
          this.getPurchaseById(idPurchase, resolve);
        });
        getPurchasePromise.then((purchase) => {
          let dateSelected = new URLSearchParams(window.location.search).get(
            "dateSelected"
          );
          this.updateCheckout({ checkout: purchase });
          this.updateDateSelected({
            dateSelected: dateSelected,
            notUpdateCheckout: true,
          });
          this.nextStep();
        });
      }

      this.canShow = true;
      this.isMultiple = this.isMultiPrice();
    },
    openModalCancelPurchase: function () {
      if (confirm(this.$t("cancelModal"))) {
        this.confirmCancelPurchase();
      }
    },
    confirmCancelPurchase: function(){
      this.cleanData();
      this.cancelPurchase();
      this.refreshPurchase();
    },
    cleanData: function(){
      const checkout = this.getCheckout();
      checkout.mail = "";
      checkout.name = "";
      checkout.dni = "";
      checkout.phone = "";
      this.updateCheckout({ checkout });
      this.refreshState();
    },
    refreshPurchase: function () {
      this.refreshState();
      this.updateShowStepOne(true);
      this.updateShowStepTwo(false);
      this.showCalendar = true;
      this.stepOneKey++;
    },
    cancelPurchase: function () {
      if (this.getCheckout().id) {
        let cancelPromise = new Promise((resolve) => {
          this.cancelPurchaseApi(resolve);
        });
        cancelPromise.then(() => {
          this.refreshPurchase();
          this.returnFromCheckout = true;
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getCheckout", "isMultiPrice",'canShowStepOne', 'canShowStepTwo']),
  },
};
</script>

<style lang="scss" scoped>
#aion-checkout {
  .general {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    &.multi-price {
      max-width: 414px;
      flex-wrap: wrap;
      .left-box-zone,
      .right-box-zone {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
