import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import api from '@/api';

export default {
    extends: api,
    props: ["callSunday"],
    data() {
        return {
            keyPaymentMethod: 0,
            optionPaymentSelected: 1,
            labelSend: this.$t('sendReserve'),
            paymentMethods: [],
            paymentMethodSelected: null
        }
    },
    computed: {
        ...mapGetters(['getState', 'getToken', 'getDesign', 'getCheckout', 'getExperiences', 'getExperiencesCheckout', 'getPaymentMethods', 'getDateSelected']),
        getTotalPriceCalcutate: function () {
            let total = 0;
            const checkout = this.getCheckout();
            checkout.items.forEach((item) => {
                total += Number(item.price_original);
                item.fields.forEach((field) => {
                    if (field.name.includes('*')) {
                        total += field.amount * item.people_number;
                    } else if (!field.name.includes('=')) {
                        total += field.amount;
                    }
                });
            });

            const filedsPerReservation = checkout.items.map(i => i.fields).flat().filter(x => x.name.includes("+="));
            // Los campos que se cobran por reserva solo se deneben de cobrar una vez pero la estrucutra de datos pone varios por cada item
            const extraPerReservation = this.unique(filedsPerReservation).reduce((total, item) => total + item.amount, 0);

            if (extraPerReservation) {
                total += extraPerReservation;
            }

            return parseFloat(total).toFixed(2);
        },
        getFractionedPriceCalcutate: function () {
            let total = 0;
            this.getCheckout().items.map((item) => {
                total += item.price_fractioned;
            });
            return parseFloat(total).toFixed(2);
        },
        getTotalPriceAfterDiscount: function () {
            return parseFloat(this.getCheckout().price_after_discount).toFixed(2);
        },
        isPartialPrice: function () {
            let isPartialPrice = [];
            this.getExperiencesCheckout().map((experience) => {
                isPartialPrice.push(experience.prices.partial_price);
            });

            return isPartialPrice.some((value) => value !== 0);
        },
        isForcePartial: function () {
            let isForcePartialComplete = [];
            this.getExperiencesCheckout().map((experience) => {
                isForcePartialComplete.push(experience.prices.force_partial);
            });
            let all = isForcePartialComplete.every((value) => value == true);
            if (all) {
                if (this.getTotalPriceAfterDiscount > 0) {
                    this.optionPaymentSelected = 2;
                    this.changeTotalToPaid(2);
                }
                else {
                    this.optionPaymentSelected = 1;
                    all = false;
                    this.changeTotalToPaid(1);
                }
            }
            return all;
        }
    },
    watch: {
        optionPaymentSelected: function () {
            this.changeTotalToPaid(this.optionPaymentSelected);
        },
    },
    methods: {
        ...mapMutations(['updateCheckoutField', 'updateCheckout', 'updateCheckoutPartial']),
        changePaymentMethod: function (value) {
            this.paymentMethodSelected = value;
        },
        finishPurchase: function () {
            if (this.validate()) {
                if (this.getTotalPriceAfterDiscount <= 0) {
                    this.changeTotalToPaid(1);
                }
                let updatePurchasePromise = new Promise((resolve) => {
                    this.updatePurchaseApi(resolve);
                });
                updatePurchasePromise.then((purchase) => {
                    this.disablePopUpClose();
                    let preparePaymentPromise = new Promise((resolve) => {
                        this.preparePaymentMethodApi(this.paymentMethodSelected.paymentMethodId, resolve);
                    });
                    preparePaymentPromise.then((payment) => {
                        this.$emit('change-payment-method', { payment: payment, paymentMethodId: this.paymentMethodSelected.paymentMethodId });
                        document.getElementById('submit-purchase').click();
                    });
                });
            }
        },
        copyClipboard: function () {

            let idPurchase = this.getCheckout().id;

            if (idPurchase) {
                this.labelSend = this.$t('clicSendDone');
                navigator.clipboard.writeText(window.location.href + '?checkout=' + idPurchase + '&dateSelected=' + this.getDateSelected());
            }
            else {
                this.labelSend = this.$t('notReservedDone');
            }
        },
        changeTotalToPaid: function (optionPaymentId) {
            this.updateCheckoutPartial({ is_fractioned: optionPaymentId == 2 });
        },
        disablePopUpClose: function () {
            window.onbeforeunload = function () { };
        },
        validate: function () {
            const requiredFields = Array.from(document.getElementsByClassName("required-field"));

            let isValid = true;
            let isValidatedField = false;
            for (const field of requiredFields) {
                if (!field.value) {
                    isValid = false;
                    field.classList.add('input-error');

                    if (!isValidatedField) {
                        field.scrollIntoView();
                        isValidatedField = true;
                    }
                }
            }

            return isValid;
        },
        unique: function (fields) {
            let uniques = new Map();

            for (let i = 0; i < fields.length; i++) {
                let field = fields[i];
                let key = `${field.name}-${field.title}-${field.total}-${field.value}`;
                if (!uniques.has(key)) {
                  uniques.set(key, field);
                }
                
            }

            return Array.from(uniques.values());
        }
    }

}