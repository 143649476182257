import Vue from 'vue'
import App from './App.vue'
import store from './store'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput, {placeholder: 'XXXXXXXXX'});

import UUID from "vue-uuid";
Vue.use(UUID);

import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

import { languages } from '@/translations';
import { defaultLocale } from '@/translations';

// Obtener las dos primeras letras después de la primera barra en la URL
const urlLocale = window.location.pathname.split('/')[1].substring(0, 2);

// Validar y usar el idioma de localStorage, la URL o el predeterminado si no es válido
const storedLocale = localStorage.getItem('data-integration-aion-locale');
const locale = storedLocale || (urlLocale === 'en' || urlLocale === 'es'|| urlLocale === 'gl'|| urlLocale === 'ca'|| urlLocale === 'fr' ? urlLocale : defaultLocale);

const messages = Object.assign(languages);

Vue.config.productionTip = false;

export const changeDay = new Vue();
let i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'es',
    messages
});

new Vue({
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#aion-checkout-widget');
