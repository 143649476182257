<template>
    <div class="form-group" v-bind:class="{ 'dark': getDesign() == 'black', 'white': getDesign() == 'white' }">
        <span class="text">{{ option.value }} {{ $t("por") }} {{ option.price }}€</span>

        <div class="input-container">
            <div class="input-group">
                <button @click="decrease" :disabled="value <= 0">-</button>
                <span class="input-units-value">{{ String(value) }}</span>
                <button @click="increase">+</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: "input-units",
    props: ["field", "option", "experience", "room", "noLabel", "start"],
    data() {
        return {
            value: 0,
        };
    },
    computed: {
        ...mapGetters(['getDesign', 'getCheckout', 'getFieldItems', 'findFieldItems'])
    },
    created() {
        const title = this.getTitle();
        const fieldTemp = this.findFieldItems({ id_experience: this.experience, title: title });
        

        if (fieldTemp) {
            this.value = fieldTemp.total
        }
        else if (!this.field.isRequired) {
            this.value = 0;
        }

    },
    methods: {
        ...mapMutations(['updateItemField']),

        increase(event) {
            event.preventDefault();

            this.value++;
            this.changeField();
        },
        decrease(event) {
            event.preventDefault();

            if (this.value > 0) {
                this.value--;
                this.changeField();
            }
        },
        changeField() {
            const price = this.option.price * this.value;

            const newField = {
                id: this.$uuid.v4(this.field.id),
                name: this.option.name,
                title: this.getTitle(),  // Identifica por titulo,
                value: this.option.value,
                amount: price,
                total: this.value,
                type: 'units',
                parentTitle: this.field.name,
            };

            this.updateItemField({
                id_experience: this.experience,
                id_calendario: this.room,
                newField,
                start: this.start
            });

            if (this.getCheckout().id) {
                this.$emit('change-field');
            }
        },
        getTitle() {
            return this.field.name + " " + this.option.value;
        },
    }
};
</script>

<style scoped>
.text {
    margin-left: 15px;
    color: #667277;
    font-size: medium;
    font-weight: normal;
}

.form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
}

label {
    flex: 1;
    font-weight: bold;
}

.input-container {
    display: flex;
    justify-content: flex-end;
    width: auto;
}

.input-group {
    display: flex;
    align-items: center;
    overflow: hidden;
    background: white;
    height: 50px;
}

.input-units-value {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #778489;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: white;
}

.input-group button {
    background-color: #ddd;
    color: #778489;
    border: none;
    padding: 5px 8px;
    /* Se mantiene compacto */
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
}

.input-group button:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.dark {
    background-color: #333;
    color: white;
}

.white {
    background-color: #fff;
    color: black;
}
</style>
