import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import ItemCheckout from './../../../classes/ItemCheckOut';

export default {
    props: ['schedule','calendar','experienceContent','currentDay','currentDayPlus','dateSelected','callSunday', 'call'],
    data() {
        return {
            scheduleSelected: false,
            scheduleDesktop: false,
            scheduleMobile: false,
            openModal: false,
        }
    },
    computed: {
        ...mapGetters(['getCheckout','getDesign','isMultiPrice','getPlayersSelected']),
        checkSchedule : function () {
            const checkDelayResponse = this.checkDelay();
            const checkPassDay = this.checkPassDay();
            const block = !this.call && checkDelayResponse || !this.checkFree();

            return {
                'selected' : !checkPassDay && this.scheduleSelected,
                'call': this.call && !checkPassDay && checkDelayResponse,
                'free': !checkPassDay && this.checkFree() && !block,
                'block' : checkPassDay || block,
                'dark' : this.getDesign() == "black",
                'white' : this.getDesign() == "white"
            }
        }
    },
    created() {
        this.checkDevice();
    },
    methods: {
        ...mapMutations(['addItem','removeItem']),
        checkDevice: function () {
            const toMatch = [
                /Android/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            this.scheduleMobile = toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });

            this.scheduleDesktop = !this.scheduleMobile;
        },
        checkDelay: function () {
            if(this.checkFree()) {
                this.currentDay = this.currentDay ?? new Date();
                const startSession = this.schedule.start_session.split(':');
                const dateSelected = this.dateSelected.split('/');
                const roomDate = new Date(dateSelected[2], dateSelected[1]-1, dateSelected[0], startSession[0], startSession[1], 0);

                return this.currentDay.getTime() <= roomDate.getTime() &&  this.currentDayPlus.getTime() >= roomDate.getTime();
            }
            else {
                return false;
            }
        },
        checkPassDay: function () {
            this.currentDay = this.currentDay ?? new Date();
            const startSession = this.schedule.start_session.split(':');
            const dateSelected = this.dateSelected.split('/');
            const roomDate = new Date(dateSelected[2], dateSelected[1]-1, dateSelected[0], startSession[0], startSession[1], 0);

            return roomDate.getTime() < this.currentDay.getTime();
        },
        checkPlayers: function(){
            const availableRooms = Math.ceil(this.getPlayersSelected() / this.experienceContent.maxPlayers);
            const schedules = this.experienceContent.calendars.map(cal => cal.schedules).flat(1);
            const currentSchedules = schedules.filter(s => s.start_session === this.schedule.start_session && s.isFree);

            return currentSchedules.length >= availableRooms;
        },
        checkFree: function(){
            return this.isMultiPrice() ? this.checkPlayers() : this.schedule.isFree;
        },
        selectSchedule: function (isBlock) {
            if(this.checkFree() && !isBlock) {
                let newFormatDateSplit = this.dateSelected.split('/');
                let newDate = newFormatDateSplit[2] + '-' + newFormatDateSplit[1].padStart(2, "0") + '-' + newFormatDateSplit[0];

                if(!this.isMultiPrice()){
                    this.createCheckout(newDate);
                }else{
                    this.createMultiPriceCheckout(newDate);
                }

                this.$emit('schedule-selected');
            }
        },
        getLanguage() {
            const field = this.experienceContent.fields.find(f => f.isLanguage)
            return field && field.options.length ? field.options[0].value : '';
        },
        getPrice(){
            let price = this.experienceContent.prices.prices[0].full_price;
            if(this.isMultiPrice()){
                const experiencePrice = this.experienceContent.prices.prices.find(p => p.people === this.getPlayersSelected());
                const currentPrice = experiencePrice ? experiencePrice.full_price : this.experienceContent.prices.prices.find(p => p.people === 999).full_price;
                price = currentPrice * this.getPlayersSelected();
            }

            return price;
        },
        createCheckout(newDate){
            let itemCheckout = new ItemCheckout(
                this.experienceContent.id,
                this.calendar,
                newDate + ' ' + this.schedule.start_session,
                this.schedule.start_session.slice(0,5),
                newDate + ' ' + this.schedule.end_session,
                this.schedule.end_session.slice(0,5),
                this.experienceContent.prices.prices[0].people,
                this.getPrice(),
                this.experienceContent.prices.partial_price,
                this.getLanguage()
            );

            this.scheduleSelected = !this.scheduleSelected;
            if(this.scheduleSelected) this.addItem({item: itemCheckout});
            else this.removeItem({item: itemCheckout});
        },
        createMultiPriceCheckout(newDate){
            const availableRooms = Math.ceil(this.getPlayersSelected() / this.experienceContent.maxPlayers);
            let currentPlayers = this.getPlayersSelected();
            const availableCalendars = this.experienceContent.calendars.filter(c => c.schedules.find(s => s.start_session === this.schedule.start_session && s.isFree));
            for(let i = 0; i < availableRooms; i++){
                currentPlayers = currentPlayers
                const itemCheckout = new ItemCheckout(
                    this.experienceContent.id,
                    availableCalendars[i].id,
                    newDate + ' ' + this.schedule.start_session,
                    this.schedule.start_session.slice(0,5),
                    newDate + ' ' + this.schedule.end_session,
                    this.schedule.end_session.slice(0,5),
                    currentPlayers > this.experienceContent.maxPlayers ? this.experienceContent.maxPlayers : currentPlayers,
                    i === 0 ? this.getPrice() : 0,
                    this.experienceContent.prices.partial_price,
                    this.getPlayersSelected(),
                    this.getLanguage()
                );

                currentPlayers -= this.experienceContent.maxPlayers;
                this.addItem({item: itemCheckout})
            }
        }
    }
}