export function mergeCheckout(existingCheckout, newCheckout) {
    return {
        ...newCheckout,
        items: newCheckout.items.map((item, index) => {
            const selectedItem = existingCheckout.items[index];
  
            if(!selectedItem) {
                return item;
            }

            return {
                ...item,
                fields: item.fields.map(field => {
                    const selectedField = selectedItem.fields.find(existingField => existingField.title === field.title);

                    if(!selectedField) {
                        return field;
                    }

                    return {
                        ...selectedField,
                        ...field,
                    }
                })
            }
        }) 
    }

}

export function removeFieldsWithEmptyValue(checkout) {
    return {
        ...checkout,
        items: checkout.items.map(item => {
            return {
                ...item,
                fields: item.fields.filter(field => field.value.trim() !== '')
            }
        })
    }
}