import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import {changeDay} from "@/main";
import api from '@/api';

export default {
    extends: api,
    props: ['moreInfo','urlExperience','multiBooking','showExperienceName','showRoomName','callSunday','call'],
    data() {
        return {
            noExperience: false,
            experiencies: []
        }
    },
    computed: {
        ...mapGetters(['getDateSelected', 'getExperiences','isMultiPrice']),
        transformDate() {
            // Obtener el idioma de la URL
            const urlLocale = window.location.pathname.split('/').pop();
            // Obtener las dos primeras letras del idioma
            function getLocale() {
                switch (true) {
                    case urlLocale.startsWith('ca'):
                        return 'ca-ES';
                    case urlLocale.startsWith('en'):
                        return 'en-EN';
                    case urlLocale.startsWith('gl'):
                        return 'gl';
                        case urlLocale.startsWith('fr'):
                        return 'fr';
                    default:
                        return 'es-ES';
                }
            }
            // Mapeo manual de los nombres completos de los meses en gallego
            const monthNamesGalician = [
                'xaneiro', 'febreiro', 'marzo', 'abril', 'maio', 'xuño',
                'xullo', 'agosto', 'setembro', 'outubro', 'novembro', 'decembro'
            ];

            const dateSelected = this.getDateSelected().split('/');
            const newDateSelected = new Date(dateSelected[2], dateSelected[1] - 1, dateSelected[0], 0);
            const locale = getLocale();

            let monthName;
            if (locale === 'gl') {
                monthName = monthNamesGalician[newDateSelected.getMonth()];
            } else {
                monthName = newDateSelected.toLocaleString(locale, { month: 'long' });
            }

            return `${newDateSelected.getDate()} - ${monthName}`;
        }
    },
    created() {
        this.getExperiencies();
    },
    methods: {
        ...mapMutations(['updateExperiences','updateCheckout','updateIsMultiplePrice','updatePlayersSelected']),
        getExperiencies: function () {
            let experiencePrommise = new Promise((resolve) => {
                this.getExperiencesByIdApi(resolve);
            });
            experiencePrommise.then((experiences) => {
                this.experiencies = experiences;
                this.noExperience = experiences.length === 0;

                this.updateIsMultiplePrice({experiences: experiences});
                this.updateExperiences({experiences: experiences});
                this.$emit('load-experience');
            });
        },
        onExperienceSelected: function () {
            this.$emit('next-step');
        },
        dayBeforeHeader: function () {
            changeDay.$emit('day-before');
        },
        dayAfterHeader: function() {
            changeDay.$emit('day-after');
        },
        onSelectPlayers(playersSelected){
            this.updatePlayersSelected(playersSelected);
            this.getExperiencies();
        }
    }
}
