<template>
    <div id="line-resume-content">
        <div class="line-resume" v-for="(room, index) in getItems" v-bind:key="index">
            <p class="title-line-resume">
                {{ experenceById(room.id_experience).name }} <br />
                {{ getDateSelected() }} {{ $t('a las') }} {{ roomHour(room) }}
            </p>
            <div class="subtitle-line-resume">
                <p>- {{ room.people_number }} {{ $t('personas') }} </p>
                <p>{{ room.price_original.toFixed(2) }}€</p>
            </div>
            <div class="subtitle-line-resume" v-for="(field, index) in getFields(room, getItems, index)"
                v-bind:key="index">
                <p>- {{ field.title }} : {{ field.value }}</p>
                <p>{{ field.amount.toFixed(2) }}€</p>
            </div>
            <div v-if="room.discount_amount > 0" class="resume-inline-discount">
                <p>Código {{ getCheckout().code }}</p>
                <p>- {{ Number(room.discount_amount).toFixed(2) }}€</p>
            </div>

            <div v-for="(groupFileds, index) in getFiledTypeUinits(room)" class="fields-units" v-bind:key="index">
                <p class="fields-units-title">- {{ groupFileds.title }}:</p>
                <div class="subtitle-line-resume fields-units-item" v-for="(field, index) in groupFileds.fields" v-bind:key="index">
                    <p>- {{ field.value }} x{{ field.total }}</p>
                    <p>{{ field.amount.toFixed(2) }}€</p>
                </div>
            </div>
        </div>
        <div class="fields-per-reservation" v-for="(field, index) in getFieldsByReserve(getItems)" v-bind:key="index">
            <p>{{ field.title }} {{ field?.amount?.toFixed(2) }}€</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "line-resume-content",
    computed: {
        ...mapGetters(['getCheckout', 'getExperiences', 'getDateSelected', 'isMultiPrice', 'getPlayersSelected', 'isMinimal']),
        roomHour: function () {
            return (room) => {
                const date = room.start.includes('T') ? new Date(room.start.replace(' ', 'T')) : new Date(room.start);
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                return `${hours}:${minutes}h`;
            };
        },
        experenceById: function () {
            return (id_experience) => {
                return this.getExperiences().filter((experience) => { return experience.id === id_experience })[0]
            }
        },
        getItems: function () {
            let items = this.getCheckout().items;
            if (this.isMultiPrice()) {
                const item = { ...items[0] };
                if (this.isMinimal()) {
                    item.people_number = items.reduce((accumulator, currentItem) => accumulator + currentItem.people_number, 0);
                } else {
                    item.people_number = item.total_players || this.getPlayersSelected();
                }
                item.price_original = items.reduce((accumulator, currentItem) => accumulator + Number(currentItem.price_original), 0);
                item.discount_amount = items.reduce((accumulator, currentItem) => accumulator + Number(currentItem.discount_amount), 0);
                items = [item];
            }

            return items;
        }
    },
    methods: {
        getFields: function (room, items, index) {
            const experience = this.experenceById(room.id_experience);
            let playersSelected = this.getPlayersSelected();
            playersSelected = playersSelected > 0 ? playersSelected : room.people_number;
            let fields = room.fields.filter(f => f.amount > 0).filter(f => f?.type !== "units");
            let fieldsResponse = [];

            fields = fields.map(field => {
                let amount = field.amount * Math.ceil(playersSelected / experience.maxPlayers);
                let value = `${field.value} ${amount}€`;

                if (field.name.includes('*')) {
                    value = `${value} por persona`;
                    amount = field.amount * playersSelected;
                } else if (field.name.includes('+=')) {
                    value = `${field.value}`;
                    amount = field.amount;
                }

                fieldsResponse.push({
                    ...field,
                    value,
                    amount
                });

                return field;
            })

            const selectedFields = fieldsResponse.length > 0 ? fieldsResponse : fields;

            /*
            Ahora se ocultan todos los pagos extra por reserva porque se quiere añadir en una linea aparte en lugar de cada item (solo para los que nos son multi reserva)
            */


            if (this.isMultiPrice()) {
                return selectedFields;
            } else {
                return selectedFields.filter(field => !field.name.includes('+='));
            }
        },
        getFieldsByReserve: function (items) {
            if (this.isMultiPrice()) {
                return [];
            }
            const firstItem = items[0];
            const filedsByReserve = firstItem.fields.filter(field => field.name.includes("+="));

            //  cuando la respuesta viene del backend rellana un campo amount pero cuando los datos se calculan en el del frontend se pone total
            return filedsByReserve.map(field => {
                return {
                    ...field,
                    amount: field.total ?? (field.amount * items.length)
                }
            });
        },
        getFiledTypeUinits: function (room) {
            let fields = room.fields.filter(f => f?.type === "units");
            const groups = fields.reduce((acc, item) => {
                const key = item.parentTitle;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {})

            const results = Object.entries(groups).map(([key, value]) => {
                return {
                    title: key,
                    fields: value
                }
            });

            return results;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_variables_white.scss";
@import "@/scss/_variables_dark.scss";

#aion-checkout {
    #line-resume-content {
        .line-resume {
            width: calc(100% - 12px);
            margin-left: 12px;

            .title-line-resume {
                color: $primary-color-text-white;
                text-transform: uppercase;
                font-size: 22px;
                line-height: 24px;
                margin-bottom: 20px;
            }

            .subtitle-line-resume {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 15px;
                margin-left: 20px;

                * {
                    letter-spacing: 3px;
                    color: $primary-color-text-white;
                }

                p {
                    text-transform: initial;
                    font-family: 'DIN NextLTPro Condensed', SansSerif;
                    font-size: 18px;
                    line-height: 22px;

                    span {
                        display: inline-block;
                        margin-left: 13px;
                    }
                }
            }

            .resume-inline-discount {
                margin-top: 25px;
                margin-bottom: 15px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                color: $green-color;
            }
        }
    }

    .multi-price {
        #line-resume-content {
            .line-resume {
                .title-line-resume {
                    font-size: 19px;
                    line-height: 20px;
                }

                .subtitle-line-resume,
                .resume-inline-discount {
                    font-size: 16px;
                    margin-left: 0;

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .fields-per-reservation {
        color: $primary-color-text-white;
        text-transform: uppercase;
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 20px;
        margin-left: 12px;
    }

    .fields-units {
        letter-spacing: 3px;
        color: rgb(102, 114, 119);
        margin-left: 20px;
    }

    .fields-units-title {
        padding: 0px;
        margin: 0px;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 3px;
        font-family: "DIN NextLTPro Condensed", SansSerif;
        height: 45px;
    }

    .fields-units-item {
        margin-bottom: 0px!important;
        margin-left: 30px!important;
        height: 35px;
    }

    @media screen and (max-width: 1024px) {
        #line-resume-content {
            .line-resume {
                .title-line-resume {
                    font-size: 19px;
                    line-height: 20px;
                }

                .subtitle-line-resume,
                .resume-inline-discount {
                    font-size: 16px;
                    margin-left: 0;

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>
