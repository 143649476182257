<template>
    <div class="content">
        <div id="experience-selector" v-if="showExperiences">
            <minimal-experiences
                :more-info="showMoreInfoExperience"
                :show-experience-name="showExperienceName"
                :show-room-name="showRoomName"
                :multi-booking="multiBooking"
                :call-sunday="callSunday"
                :url-experience="urlInfoExperience"
                @next-step="onNextStepOne"
            />
        </div>
        <div id="calendar-selector" class="minimal-width" v-if="showDateSelection">
            <calendar-selector :call="call" @next-step="onNextStepTwo" @back="onBackSelected"/>
        </div>
        <div id="checkout-final" class="minimal-width" v-if="showCheckout">
            <minimal-checkout @back-step="onModify" :terms="terms"/>
        </div>
        <div v-if="showDateSelection && getTotalPriceCalcutate > 0" class="form-group-price">
            <div class="price-number">
                <span>{{ $t('Precio') }}:</span>
                <span>{{ getTotalPriceCalcutate }}€</span>
            </div>
            <div class="button button-flat" v-if="getCheckout().items[0].start" @click="onNextStepLast">{{ $t('Siguiente') }}</div>
        </div>
        <div class="powered-by">powered by <a href="https://proescape.es/servicios/sistema-gestor-de-reservas/">AION</a></div>
    </div>
</template>

<script>
    import MinimalExperiences from './experiences/minimal-experiences.vue';
    import CalendarSelector from './calendar-selector/calendar-selector.vue'
    import MinimalCheckout from './checkout/minimal-checkout.vue';
    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: 'minimal-calendar',
        components: {            
            CalendarSelector,
            MinimalExperiences,
            MinimalCheckout
        },
        props: ['integration'],
        data(){
            return {
                call: this.integration.call,
                callSunday: this.integration.sundayCall,
                isAvailableDayShow: false,
                moreInfoText: this.integration.text ?? '',
                minHeight: 0,
                multiBooking: this.integration.multibooking,
                showCheckout: false,
                showDateSelection: false,
                showExperiences: true,
                showExperienceName: this.integration.calname,
                showMoreInfoExperience: this.integration.moreinfo,
                showMoreInfoGeneral: this.integration.info,
                showRoomName: this.integration.line,
                terms: this.integration.terms ?? '',
                urlInfoExperience: JSON.parse(this.integration.urls)
            }
        },
        methods: {
            ...mapMutations(['removeItems']),
            onNextStepOne: function () {
                this.showExperiences = false;
                this.showDateSelection = true;
            },
            onNextStepTwo: function () {
                this.showDateSelection = false;
                this.showCheckout = true;
            },
            onNextStepLast: function(){
                this.showExperiences = false;
                this.showDateSelection = false;
                this.showCheckout = true;
            },
            onBackSelected: function(){
                this.removeItems();
                this.showCheckout = false;
                this.showDateSelection = false;
                this.showExperiences = true;
            },
            onModify: function(){
                this.showCheckout = false;
                this.showDateSelection = true;
            }
        },
        computed: {
            ...mapGetters(['getDesign','getExperiences','getCheckout']),
            getTotalPriceCalcutate: function () {
                let total = 0;
                let totalPlayers = 0;
                this.getCheckout().items.forEach((item) => {
                    if(item.start) {
                        totalPlayers += item.people_number;
                        let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                        total = (experienceSelected.prices.prices.find(price => price.people === totalPlayers))
                        ? (experienceSelected.prices.prices.find(price => price.people === totalPlayers).full_price * totalPlayers) 
                        : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * totalPlayers);
                    }
                });

                return parseFloat(total).toFixed(2);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    body {
        background-color: #000;
    }

    .content{
        width: 100%;
    }

    .form-group-price {
        padding: 15px 30px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: $background-general-more-info-button-white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-number {
            color: #fff;
            text-transform: uppercase;
            span {
                font-size: 35px;
                line-height: 38px;
            }
        }
        .button-flat {
            width: 130px;
        }
    }
</style>
