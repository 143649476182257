import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
    props: ["schedule","currentDay","currentDayPlus","experienceContent", "numberPlayers", "call"],
    data() {
        return {
            scheduleSelected: false,
            scheduleDesktop: false,
            scheduleMobile: false,
            openModal: false,
        }
    },
    computed: {
        ...mapGetters(['getCheckout','getDesign','getDateSelected']),
        checkSchedule : function () {
            const checkDelayResponse = this.checkDelay();
            const checkPassDay = this.checkPassDay();
            const block = !this.call && checkDelayResponse || !this.schedule.isFree;
            
            return {
                'selected' : !checkPassDay && this.scheduleSelected,
                'call': this.call && !checkPassDay && checkDelayResponse,
                'free': !checkPassDay && this.schedule.isFree && !block,
                'block' : checkPassDay || block,
                'dark' : this.getDesign() == "black",
                'white' : this.getDesign() == "white"
            }
        },
    },
    created() {
        this.checkDevice();
    },
    methods: {
        ...mapMutations(['addItem','removeItem']),
        checkDevice: function () {
            const toMatch = [
                /Android/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            this.scheduleMobile = toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });

            this.scheduleDesktop = !this.scheduleMobile;
        },
        checkDelay: function () {
            if(this.schedule.isFree) {
                const startSession = this.schedule.start_session.split(':');
                const dateSelected = this.getDateSelected().split('/');
                const roomDate = new Date(dateSelected[2], dateSelected[1]-1, dateSelected[0], startSession[0], startSession[1], 0);

                return this.currentDay.getTime() <= roomDate.getTime() &&  this.currentDayPlus.getTime() >= roomDate.getTime();
            }
            else {
                return false;
            }
        },
        checkPassDay: function () {
            const startSession = this.schedule.start_session.split(':');
            const dateSelected = this.getDateSelected().split('/');
            const roomDate = new Date(dateSelected[2], dateSelected[1]-1, dateSelected[0], startSession[0], startSession[1], 0);

            return roomDate.getTime() < this.currentDay.getTime();
        },

        checkIsAvaliable: function () {
            let numberCalendarsForSchedule = 0;
            this.experienceContent.calendars.forEach((calendar) => {
                calendar.schedules.forEach((itemSchedule) => {
                    if(itemSchedule.start_session === this.schedule.start_session && itemSchedule.isFree) numberCalendarsForSchedule++;
                })
            });

            const maxNumberPeopleAcceptForSchedule = numberCalendarsForSchedule * this.experienceContent.maxPlayers;

            return maxNumberPeopleAcceptForSchedule >= this.numberPlayers;
        },

        selectSchedule: function (isBlock) {
            if(!isBlock){
                this.$emit('schedule-selected',{
                    start_session: this.schedule.start_session, 
                    end_session: this.schedule.end_session,  
                    id_calendar: this.schedule.id_calendar
                });
            }
        }
    }
}