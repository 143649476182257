<template>
    <div class="form-group" :class="{ 'dark': getDesign() === 'black', 'white': getDesign() === 'white' }">
        <label>{{ $t(this.field.name) }}</label>
        <select v-model="value" :required="field.isRequired" class="language-select" @change="changeInput">
            <option v-for="option in field.options" :key="option.id" :value="option.value">
                {{ changeNameLanguage(option.name) }}
            </option>
        </select>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
    name: "select-general-language",
    props: ["field"],
    data() {
        return {
            value: '',
        };
    },
    computed: {
        ...mapGetters(['getDesign', 'getCheckout']),
        changeNameLanguage() {
            return (name) => {
                const translations = {
                    'Spanish': this.$t('Español'),
                    'English': this.$t('English'),
                    'Catalan': this.$t('Català'),
                    'Galician': this.$t('Galego')
                };
                return translations[name] || name;
            };
        }
    },
    created() {
        this.value = this.getCheckout().language || this.field.options[0]?.value || '';
        this.changeInput();
    },
    methods: {
        ...mapMutations(['updateCheckoutField', 'updateCheckoutItemsLanguage']),
        changeInput() {
            let checkout = this.getCheckout();
            let langueage = this.value;

            this.updateCheckoutField({ name: 'language', value: langueage });
            this.updateCheckoutItemsLanguage(langueage);

            if (checkout.id) {
                this.$emit('change-field');
            }
        }
    }
};
</script>

<style scoped>
.language-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style>
