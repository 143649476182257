import es from './es.json'
import en from './en.json'
import ca from './ca_ES.json'
import gl from './gl_ES.json'
import fr from './fr_FR.json'

export const defaultLocale = 'es';

export const languages = {
    es: es,
    en_US: en,
    ca_ES: ca,
    gl_ES: gl,
    fr_FR: fr
};
