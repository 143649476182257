<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label for=""> {{ $t('Número de personas') }}</label>
        <select v-if="scheduleDesktop" v-model="idPriceSelected" @change="changeNumber">
            <option :value="index" v-for="(price, index) in experience.prices.prices" v-bind:key="index" v-if="price.full_price > 0">
                {{ price.people }} {{ $t('PERSONAS') }} &nbsp;&nbsp;&nbsp;&nbsp; {{ (price.full_price/price.people).toFixed(2) }}€/{{ $t('PERSONA') }} &nbsp;&nbsp;&nbsp;&nbsp; {{ price.text }}
            </option>
        </select>
        <select v-else v-model="idPriceSelected" name="" id="" @change="changeNumber">
            <option :value="index" v-for="(price, index) in experience.prices.prices" v-bind:key="index">
                {{ price.people }}
                {{ $t('PERSONAS') }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ (price.full_price/price.people).toFixed(2) }}€/{{ $t('PERSONA') }} &nbsp;&nbsp;&nbsp;&nbsp;
                {{ price.text }}
            </option>
        </select>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    export default {
        props: ["room","experience","id_field","start"],
        name: "player-selector",
        data() {
            return {
                idPriceSelected: 0,
                scheduleDesktop: false,
                scheduleMobile: false,
            }
        },
        computed: {
            ...mapGetters(['getCheckout','getDesign','getNumPlayerItems','getExperiences']),
        },
        created() {
            this.checkDevice();
            let number = this.getNumPlayerItems({id_experience: this.experience.id, id_calendario : this.room});

            if(number) {
                this.idPriceSelected = this.getPositionValue(number);
            }
            this.changeNumber();
        },
        methods: {
            ...mapMutations(['updatePeopleNumber']),
            checkDevice: function () {
                const toMatch = [
                    /Android/i,
                    /iPhone/i,
                    /iPad/i,
                    /iPod/i,
                    /BlackBerry/i,
                    /Windows Phone/i
                ];
                this.scheduleMobile = toMatch.some((toMatchItem) => {
                    return navigator.userAgent.match(toMatchItem);
                });
                this.scheduleDesktop = !this.scheduleMobile;
            },
            getPositionValue: function (value) {
                for (let i = 0; i < this.experience.prices.prices.length; i += 1) {
                    if (this.experience.prices.prices[i].people === value) {
                        return i;
                    }
                }
            },
            changeNumber: function () {
                let people = this.experience.prices.prices[this.idPriceSelected].people;
                let price = this.experience.prices.prices[this.idPriceSelected].full_price;
                this.updatePeopleNumber({id: this.id_field, id_experience: this.experience.id, id_calendario : this.room, people: people, price: price, start: this.start});
                if(this.getCheckout().id) {
                    this.$emit('change-field')
                }
            }
        }
    }
</script>

<style scoped>

</style>
