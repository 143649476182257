import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import api from '@/api';

export default {
    extends: api,
    props: ['terms','callSunday'],
    data() {
        return {
            showCheckoutStepOne: true,
            loading: true,
            showCalendar: false,
            showPaymentsForm: false,
            checkedConditions: false,
            stepOneKey: 0,
            paymentMethodId: 1,
            paymentMethod: null,
            showFormsPayments: false,
            stripeKey: 0,
            resumeKey: 0
        }
    },
    created() {
        window.onbeforeunload = function () {
            return '';
        };
        window.visibilitychange = function () {
            return '';
        };

        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                location.reload();
            }
        });

    },
    computed: {
        ...mapGetters(['getDateSelected','getCheckout','getExperiences','getDesign','getToken', 'getItemsExperience','getPlayersSelected']),
        roomHour: function () {
            return (room) => {
                if(!room) return '';
                let startHour = room.startHour;
                if(!startHour){
                    const date = new Date(room.start);
                    const hour = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    startHour = `${hour}:${minutes}`;
                }
                return startHour;
            }
        },
        totalPeople: function () {
            let people = 0;
            this.getCheckout().items.forEach((item) => { people += item.people_number });
            return people;
        },
        getTotalPriceCalcutate: function () {
            let total = 0;
            let totalPlayers = 0;
            const playersSelected = this.getPlayersSelected();
            this.getCheckout().items.forEach((item) => {
                if(item.start) {
                    totalPlayers += item.people_number;
                    let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                    total = (experienceSelected.prices.prices.find(price => price.people === totalPlayers))
                    ? (experienceSelected.prices.prices.find(price => price.people === totalPlayers).full_price * totalPlayers) 
                    : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * totalPlayers);

                    item.fields.forEach((field) => {
                        if(field.name.includes('*')){
                            total += field.amount * playersSelected;
                        }else{
                            total += (field.amount * Math.ceil(playersSelected / experienceSelected.maxPlayers));
                        }
                    });
                }
            });

            return parseFloat(total).toFixed(2);
        },
    },
    methods: {
        ...mapMutations(['updateIdPurchase','updateCheckout','refreshState','updateCheckoutField']),
        createNewPurchase: function () {
            let createPurchasePrommise = new Promise((resolve) => {
                this.createPurchaseApi(resolve);
            });
            createPurchasePrommise.then((purchase) => {
                this.loading = true;
                this.resumeKey++;
                this.updateIdPurchase({idPurchase: purchase.id});
                this.updateCheckout({checkout: purchase});
            });
        },
        changePaymentMethod: function (value) {
            this.paymentMethod = value.payment;
            this.paymentMethodId = value.paymentMethodId
        },
        nextStep: function () {
            const requiredFields = Array.from(document.getElementsByClassName("required-field"));

            let isValid = true;
            let isValidatedField = false;

            requiredFields.forEach(field => {
                isValid = !!field.value;

                if(field.type === 'email'){
                    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    isValid = regex.test(field.value);

                    if(isValid){
                        field.nextElementSibling.classList.remove('show');
                    }else{
                        field.nextElementSibling.classList.add('show')
                    }
                }

                if(!isValid){
                    field.classList.add('input-error');

                    if(!isValidatedField){
                        field.scrollIntoView();
                        isValidatedField = true;
                    }
                }
            });
            if (isValid) {
                this.showCheckoutStepOne = false;
            }
        },
        backButton: function () {
            if(this.showCheckoutStepOne){
                const checkout = this.getCheckout();
                checkout.mail = '';
                checkout.name = '';
                checkout.dni = '';
                checkout.phone = ''; 
                this.updateCheckout({checkout})
                this.$emit('back-step');
            } else{
                this.showCheckoutStepOne = true;
            }
        },
        checkForm: function (e) {

            e.preventDefault();

            if(this.getCheckout().id) {
                this.showFormsPayments = true;

                if (this.paymentMethod.url) {
                    window.location.href = this.paymentMethod.url;

                    this.saveLocalStoragePayment();
                }
                else if (this.paymentMethod.form && this.paymentMethodId !== 3) {
                    setTimeout(() => {
                        let form = document.getElementById('final-form');
                        form.action = this.paymentMethod.form.action;
                        form.method = 'post';
                        for (let [key, value] of Object.entries(this.paymentMethod.form)) {
                            if (key !== 'action') {
                                form.innerHTML += '<input name="' + key + '" value="' + value + '" />';
                            }
                        }
                        this.saveLocalStoragePayment();
                        form.submit();
                    }, 500);
                }
                else {
                    this.stripeKey++;
                }
            }
        },
        saveLocalStoragePayment: function () {
            localStorage.setItem('toPayment', this.getCheckout().id);
            localStorage.setItem('token', this.getToken());
        }
    }
}