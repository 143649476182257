import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import api from '@/api';

export default {
    extends: api,
    computed: {
        ...mapGetters(['getToken','getDesign','getCheckout','getExperiences','getExperiencesCheckout','getDateSelected','isMultiPrice']),
        roomHour: function () {
            return (room) => {
                if(!room) return;
                
                if(room.startHour){
                    return room.startHour;
                }else {
                    const date = new Date(room.start);
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    return `${hours}:${minutes}`;
                }
            };
        },
        getRoomName: function () {
            return (id_calendar, id_experience) => {

                let calendarName = '';

                this.getExperiencesCheckout().map((experience) => {
                    if(experience.id === id_experience) {
                        experience.calendars.map((calendar) => {
                            if(calendar.id === id_calendar) {
                                calendarName = calendar.name
                            }
                        })
                    }
                });

                return calendarName;
            }
        },  
        getItemsExperience: function () {
            return (id_experience) => {
                const itemExperiences = this.getCheckout().items.filter((item) => item.id_experience === id_experience);

                itemExperiences.map(item => {
                    return {
                        ...item,
                        fields: item.fields.filter((field) => field.type !== 'date')
                    }
                })
  
                return !this.isMultiPrice() || itemExperiences.length === 0 ? itemExperiences : [itemExperiences[0]];
            }
        }
    },
    methods: {
        ...mapMutations(['updateItemField','updateCheckout','updateCheckoutField']),
        changeField: function () {
            if(this.getCheckout().id) {
                let updatePurchasePrommise = new Promise((resolve) => {
                    this.updatePurchaseApi(resolve);
                });
                updatePurchasePrommise.then((purchase) => {
                    this.updateCheckout({checkout: purchase});
                });
            }
        }
    }
}