<template>
    <div class="form-group" :class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white', 'multi-form': isMultiple && getDisplayMode() !== 'minimal'}">
        <label>{{ $t('¿Cuántos sois?') }}</label>
        <select v-model="playersSelected" @change="changeNumber">
            <option :value="player" v-for="(player, index) in players" v-bind:key="index">
               {{ getText(player)}}
            </option>
        </select>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        props: ['experience'],
        name: "select-num-players",
        data() {
            return {
                minPlayers: 0,
                maxPlayers: 0,
                players: [],
                playersSelected: 0,
                isMultiple: false
            }
        },
        computed: {
            ...mapGetters(['getDesign','getNumPlayerMinimalItems','getExperiences','isMultiPrice', 'getDisplayMode'])
        },
        created() {
            this.isMultiple = this.isMultiPrice();
            const experiences = this.getExperiences();
            const currentExperience = this.experience ?? experiences[0];
            const numPlayersSelectorFilter = currentExperience.prices.prices.filter(precio => precio.full_price === 0).map(precio => precio.people);
            this.minPlayers = currentExperience.minPlayers;
            this.maxPlayers = this.getMaxPlayers(currentExperience);
            this.playersSelected = this.getNumPlayerMinimalItems({id_experience: currentExperience.id});
            this.playersSelected = this.playersSelected > 0 ? this.playersSelected : this.minPlayers;
            /*
            la variable numPlayersSelectorFilter contiene los numeros de jugadores que no tienen se tienen que mostrar porque el precio es 0
            por eso es necesario filtrarlos en el array players
            */
            this.players = Array.from(Array(this.maxPlayers + 1 - this.minPlayers).keys()).map(player => player + this.minPlayers).filter(player => !numPlayersSelectorFilter.includes(player));
            this.changeNumber();
        },
        methods: {
            ...mapMutations(['updatePlayersSelected']),
            changeNumber: function () {
                this.updatePlayersSelected(this.playersSelected);
                this.$emit('change-field', this.playersSelected);
            },
            getText(players){
                return `${players} ${this.$t('PERSONAS')}`;
            },
            getMaxPlayers(currentExperience) {
                return currentExperience.maxPlayers * currentExperience.calendars.length;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .multi-form{
        background-color: #283032 !important;
        align-items: center;
        justify-content: center;
        display: flex;
        margin: 0;
        border-radius: 25px;

        label{
            width: 50%;
            align-items: center;
            justify-content: center;
            display: flex !important;
            margin: 0 !important;
        }

        select {
            background-color: #283032 !important;
            color: white !important;
            flex: 1;
            display: flex;
            align-items: center;
            padding-top: 1.1rem !important;
            border-radius: 25px !important;
            text-align: center;
            padding-right: 2rem !important;
        }
    }
</style>
